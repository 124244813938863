import useSupabase from '@/use/useSupabase'
import { readonly, ref } from 'vue'
import { useToast } from 'primevue/usetoast'

const { supabase } = useSupabase()
const events = ref([])
const event = ref({})

export default () => {
  const isLoading = ref(false)
  const toast = useToast()

  const fetchEvents = async () => {
    isLoading.value = true
    try {
      const { data } = await supabase
        .from('event')
        .select(
          `
      *,
      lodge (
        name
      )
      `
        )
        .order('id')
      events.value = data
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
    }
  }
  fetchEvents()

  const fetchEvent = async (id) => {
    try {
      const { data } = await supabase
        .from('event')
        .select('*, lodge(*), client(*, level(*))')
        .eq('id', id)
      event.value = data[0]
      return event.value
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
    }
  }

  const addEvent = async (event) => {
    isLoading.value = true
    try {
      await supabase.from('event').insert([event])
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Event added successfully.',
        life: 3000
      })
    }
  }

  const updateEvent = async (event, id) => {
    isLoading.value = true
    try {
      await supabase.from('event').update(event).eq('id', id)
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Event updated successfully.',
        life: 3000
      })
    }
  }

  const deleteEvent = async (id, silent = false) => {
    isLoading.value = true
    try {
      await supabase.from('event').delete().eq('id', id)
    } catch (error) {
      console.error(error)
    } finally {
      if (!silent) {
        isLoading.value = false
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Event deleted.',
          life: 3000
        })
      }
    }
  }

  const deleteEvents = async (ids) => {
    isLoading.value = true
    try {
      await Promise.all(
        ids.map(async (id) => {
          await deleteEvent(id, true)
        })
      )
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Selected events deleted.',
        life: 3000
      })
    }
  }

  return {
    addEvent,
    events: readonly(events),
    event: readonly(event),
    fetchEvents,
    fetchEvent,
    updateEvent,
    deleteEvent,
    deleteEvents,
    isLoading: readonly(isLoading)
  }
}
